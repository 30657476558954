/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveBreaks, resolveMeta } from "@dataResolvers";
import { Services as PageTemplate } from "@pageTemplates";

export const query = graphql`
query {
  craft {
    # Get three projects
    entries(section: "project", limit: 3) {
      ... on Craft_project_project_Entry {
        title
        heroDescription
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        url
        projectCategories(limit: 6) {
          ...on Craft_projectCategories_Category {
            title
            slug
          }
        }
      }
    }
    entry(section:"services") {
      ... on Craft_services_services_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Hero
        heroHeading
        heroDescription
        heroList {
          ...on Craft_heroList_listItem_BlockType {
            heading
            description
          }
        }
        heroButtonLink {
          url
          text
        }
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Our Process
        heading0,
        ourProcessProcesses {
          ...on Craft_ourProcessProcesses_process_BlockType {
            heading
            description
            processDescription
            processInformation {
              ...on Craft_processInformation_TableRow {
                label
                content
              }
            }
            color
          }
        }
        # Our Philosophy
        heading1
        descriptor0
        link0 {
          text
          url
        }
        image0 {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        heading2
        copy0
        image1 {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Working With Us
        workingWithUsServiceTypes {
          ...on Craft_workingWithUsServiceTypes_serviceType_BlockType {
            label
            heading
            listItems {
              ...on Craft_listItems_TableRow {
                listItem
              }
            }
            contactLink {
              url
              text
            }
          }
        }
        # Quote
        quoteAuthor,
        quoteContent,
        quoteAuthorImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Full Width
        heading3
        descriptor1
        linkField {
          text
          url
        }
        image2 {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
      }
    }
  }
}
`;

const previewQuery = `
query {
  craft {
    # Get three projects
    entries(section: "project", limit: 3) {
      ... on Craft_project_project_Entry {
        title
        heroDescription
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        url
        projectCategories(limit: 6) {
          ...on Craft_projectCategories_Category {
            title
            slug
          }
        }
      }
    }
    entry(section:"services") {
      ... on Craft_services_services_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Hero
        heroHeading
        heroDescription
        heroList {
          ...on Craft_heroList_listItem_BlockType {
            heading
            description
          }
        }
        heroButtonLink {
          url
          text
        }
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Our Process
        heading0,
        ourProcessProcesses {
          ...on Craft_ourProcessProcesses_process_BlockType {
            heading
            description
            processDescription
            processInformation {
              ...on Craft_processInformation_TableRow {
                label
                content
              }
            }
            color
          }
        }
        # Our Philosophy
        heading1
        descriptor0
        link0 {
          text
          url
        }
        image0 {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        heading2
        copy0
        image1 {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Working With Us
        workingWithUsServiceTypes {
          ...on Craft_workingWithUsServiceTypes_serviceType_BlockType {
            label
            heading
            listItems {
              ...on Craft_listItems_TableRow {
                listItem
              }
            }
            contactLink {
              url
              text
            }
          }
        }
        # Quote
        quoteAuthor,
        quoteContent,
        quoteAuthorImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Full Width
        heading3
        descriptor1
        linkField {
          text
          url
        }
        image2 {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const {
    title,
    uid,
    url,
    slug,
    metaTitle,
    metaDescription,
    metaImage,
    // Hero
    heroHeading,
    heroDescription,
    heroButtonLink,
    heroBackgroundImage,
    heroList,
    // Our Process
    heading0,
    ourProcessProcesses,
    // Our Philosophy
    heading1,
    descriptor0,
    link0,
    image0,
    // Working With Us
    heading2,
    copy0,
    image1,
    workingWithUsServiceTypes,
    // Quote
    quoteContent,
    quoteAuthor,
    quoteAuthorImage,
    // Full Width
    heading3,
    descriptor1,
    linkField,
    image2
  } = craft.entry;

  return {
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    hero: {
      heading: heroHeading,
      description: heroDescription,
      button: heroButtonLink,
      image: resolveImage(heroBackgroundImage),
      list: heroList.map(item => {
        return {
          heading: item.heading,
          description: item.description,
        };
      }),
    },
    process: {
      heading: heading0,
      processes: ourProcessProcesses.map(process => {
        return {
          heading: resolveBreaks(process.heading),
          description: process.description,
          processDescription: process.processDescription,
          processInformation: process.processInformation,
          color: process.color,
        };
      })
    },
    philosophy: {
      heading: heading1,
      description: descriptor0,
      link: link0,
      image: resolveImage(image0),
    },
    workingWithUs: {
      heading: resolveBreaks(heading2),
      subhead: copy0,
      image: resolveImage(image1),
      services: workingWithUsServiceTypes.map(type => {
        return {
          label: type.label,
          heading: type.heading,
          items: type.listItems.map(item => {
            return item;
          }),
          link: {
            url: type.contactLink.url,
            text: type.contactLink.text
          },
        };
      })
    },
    quote: {
      quote: quoteContent,
      author: quoteAuthor,
      image: resolveImage(quoteAuthorImage),
    },
    projects: craft.entries.map((project => {
      return {
        title: project.title,
        description: project.heroDescription,
        image: resolveImage(project.heroBackgroundImage),
        url: project.url,
        categories: project.projectCategories.map( category => {
          return {
            title: category.title,
            slug: category.slug,
          };
        }),
      };
    })),
    fullWidth: {
      heading: heading3,
      subhead: descriptor1,
      link: linkField,
      image: resolveImage(image2)
    }
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
